import { navigate } from 'gatsby'
import React, { useContext, useEffect, useState } from 'react'
import useForm from 'react-hook-form'
import { FormattedNumber } from 'react-intl'
import Swal from 'sweetalert2'
import * as api from '../../../api/cesta'
import * as apiUser from '../../../api/user'
import { processDatosFacturacion } from '../../../api/order'
import Pago from '../../../assets/images/cesta/seguro.svg'
import Next from '../../../assets/images/Talleres/next.svg'
import codigosPostales from '../../../../specific/constants/cp'
import * as CestaActions from '../../../context/actions/cestaActions'
import AppContext from '../../../context/context'
import { needsDatesExtends } from '../../../context/selectors/cestaSelectors'
import { t } from '../../../i18n'
import { PaymentObject } from '../../../../shared/cesta/type/Cesta'
import {
  checkoutPago,
  updateServerSideChanges,
} from '../../../utils/gtmCestaReporter'
import route from '../../../utils/route'
import { alertProp } from '../../../utils/swal'
import Button from '../../buttons/Button'
import FunnelSideBar from '../../funnelSideBar/FunnelSideBar'
import LayoutSelector from '../../layouts/layoutSelector/LayoutSelector'
import SpinnerDotted from '../../spinnerDotted/SpinnerDotted'
import SpinnerWithText from '../../spinnerWithText/SpinnerWithText'
import { CestaPageProps } from '../registro/CestaRegistro'
import { DataSteps, Steps } from '../Steps'
import styles from './cestaPago.module.scss'
import DatosFacturacionForm from './DatosFacturacion'
import PaymentForm from './PaymentForm'
import { isSpecialErrorMessage } from '../../../utils/specialErrorMessage'
import { ValidateCP } from '../../../../specific/helpers/validation/validationCP'
import { normalizeMatricula } from '../../../constants/matricula'
import {
  DatosFacturacionData,
  FacturacionSchema,
} from '../../../helpers/schemas/FacturacionSchema'
import { IAddressData, IUserData } from '../../../context/reducers/userReducer'

const PAYMENT_TOTAL = 'total'
const PAYMENT_PARCIAL = 'parcial'
const PAYMENT_TALLER = 'taller'

const hasFullUserDetails = (userData: IUserData, addressData: IAddressData) => {
  const { name, dni, documentType } = userData
  const { address, number, city, province, postCode } = addressData
  return (
    name &&
    dni &&
    documentType &&
    address &&
    number &&
    city &&
    province &&
    postCode
  )
}

interface TopContentProps {
  tipo_compra: string
  paymentType: string
}
const TopContent = ({ tipo_compra, paymentType }: TopContentProps) => (
  <React.Fragment>
    <div className={styles.pagoSeguro}>
      <Pago /> {t('horarios-page.pago-seguro')}
    </div>
    <div className={styles.titlesH4}>
      {paymentType !== 'taller' &&
        `${t('datos_facturacion.te_solicitamos')} ${t(
          `datos_facturacion.te_solicitamos_${tipo_compra}`
        )}`}
      {paymentType !== 'taller' ? <br /> : null}
      <div>{t('datos_facturacion.no_te_preocupes')}</div>
    </div>
  </React.Fragment>
)

interface PriceProps {
  value: number
}
const Price = ({ value }: PriceProps) => (
  <FormattedNumber style="currency" currency="EUR" value={value / 100.0} />
)

const submitForm = (doc) => {
  const form = doc.getElementsByTagName('form')[0]
  form.submit()
}

interface ContenidoProps {
  tipo_compra: string
  setPaymentType: (paymentType: string) => void
}
const Contenido = ({ tipo_compra, setPaymentType }: ContenidoProps) => {
  const { cestaState, userInfo, dispatch } = useContext(AppContext)

  const { addressData, userData } = userInfo
  const [payment, setPayment] = useState({
    totalPayment: null as null | PaymentObject,
    partialPayment: null as null | PaymentObject,
    tallerPayment: null as null | PaymentObject,
  })

  const requireMatricula = cestaState.generaFactura
  const form = useForm<DatosFacturacionData>({
    validationSchema: FacturacionSchema(requireMatricula),
  })

  const paymentType =
    (form.watch('pago') as string) ||
    (payment.totalPayment && payment.totalPayment.defaultChecked
      ? PAYMENT_TOTAL
      : PAYMENT_PARCIAL)
  const [datosFacturacion, setDatosFacturacion] =
    useState<DatosFacturacionData>(null)
  const [loading, setLoading] = useState(false)
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [paymentAttempt, setPaymentAttempt] = useState(1)
  const [paymentError, setPaymentError] = useState('')
  const [formStyles, setFormStyles] = useState({
    [PAYMENT_TOTAL]: { display: 'block', width: '100%', height: '1000px' },
    [PAYMENT_PARCIAL]: { display: 'block', width: '100%', height: '1000px' },
    [PAYMENT_TALLER]: { display: 'block', width: '100%', height: '1000px' },
  })
  const isMounted = React.useRef(true)

  const informChanges = (oldProducts, newProducts) => {
    if (oldProducts.length !== newProducts.length) {
      updateServerSideChanges(cestaState, oldProducts, newProducts)
      Swal.fire(
        alertProp({
          type: 'warning',
          text: t('cesta.errores.revision_eliminada'),
          title: 'Oops...',
        })
      )
    }
  }
  const startForm = () => {
    api
      .createOrder(cestaState, userInfo)
      .then(
        ({
          totalPayment,
          partialPayment,
          tallerPayment,
          products,
          services,
          discount,
          generaFactura,
        }) => {
          informChanges(cestaState.products, products)
          dispatch(
            CestaActions.loadCestaOk({
              products,
              services,
              generaFactura,
              discount,
            })
          )
          setPayment({
            totalPayment,
            partialPayment,
            tallerPayment,
          })
        }
      )
  }

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])
  useEffect(() => {
    if (isMounted.current) {
      startForm()
    }
  }, [paymentAttempt])

  const onSubmitFacturacion = async (data: DatosFacturacionData) => {
    try {
      if (data.matricula) {
        data.matricula = normalizeMatricula(data.matricula)
      }
      setLoading(true)
      let orderId = ''
      switch (paymentType) {
        case PAYMENT_PARCIAL:
          orderId = payment.partialPayment.ORDER_ID
          break
        case PAYMENT_TALLER:
          orderId = payment.tallerPayment.ORDER_ID
          break
        case PAYMENT_TOTAL:
          orderId = payment.totalPayment.ORDER_ID
          break
      }
      await processDatosFacturacion(
        data.document.documentValue,
        data.document.documentType,
        data.document.documentCountry,
        orderId,
        {
          name: data.name,
          surname: data.surname,
          dni: data.document.documentValue,
          address: data.address,
          poblacion: data.city,
          provincia: data.province,
          postCode: data.postCode,
          block: data.block,
          number: data.number,
          matricula: data.matricula,
        }
      )
      setLoading(false)
      setDatosFacturacion(data)
      checkoutPago(paymentType as 'total' | 'parcial' | 'taller')
    } catch ({ message }) {
      setLoading(false)
      const specialErrorMessage = isSpecialErrorMessage(message)
      if (specialErrorMessage) {
        Swal.fire(
          alertProp({
            type: 'error',
            title: 'Oops...',
            text: t(specialErrorMessage),
          })
        )
      } else {
        Swal.fire(
          alertProp({
            type: 'error',
            title: 'Oops...',
            text: t('cesta.errores.request_failed'),
          })
        )
      }
    }
  }

  const onSubmitPagoTaller = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
      setPaymentLoading(true)
      const { orderNumber } = await api.paymentResult(
        { ...payment.tallerPayment, MESSAGE: 'taller' },
        {
          fecha: cestaState.selectedDate.date,
          hora: cestaState.selectedDate.time,
        }
      )
      setPaymentLoading(false)

      dispatch(CestaActions.addPaymentType(paymentType))
      dispatch(CestaActions.addCestaOrder(orderNumber))
      dispatch(CestaActions.setStep(Steps.TU_RESERVA))
      setLoading(false)
      checkoutPago(paymentType as 'total' | 'parcial' | 'taller')
      navigate(DataSteps[Steps.TU_RESERVA].route)
      return
    } catch ({ message }) {
      setLoading(false)
      const specialErrorMessage = isSpecialErrorMessage(message)
      if (specialErrorMessage) {
        Swal.fire(
          alertProp({
            type: 'error',
            title: 'Oops...',
            text: t(specialErrorMessage),
          })
        )
      } else {
        Swal.fire(
          alertProp({
            type: 'error',
            title: 'Oops...',
            text: t('cesta.errores.request_failed'),
          })
        )
      }
    }
  }

  const onChangeCP = async () => {
    const { postCode } = form.getValues()
    if (process.env.GATSBY_WEB === 'rodimotor' && ValidateCP(postCode)) {
      const distrito = await apiUser.getDistritoFromCpPt(postCode)
      if (distrito) {
        form.setValue('province', distrito.region)
      } else {
        form.setValue('province', '-')
      }
    } else {
      const provinciaCP = codigosPostales.find((codigoPostal) =>
        postCode.startsWith(codigoPostal.cp)
      )
      if (provinciaCP) form.setValue('province', provinciaCP.provincia)
    }
  }

  const iFramesListener = () => {
    const listener = async (e) => {
      if (!e.data || typeof e.data !== 'string') {
        return
      }

      let data = {} as Record<string, any>
      try {
        data = JSON.parse(e.data)
      } catch (err) {
        if (typeof e.data === 'string') {
          if (e.data.startsWith('Error:')) {
            const [, message] = e.data.split('<BR>', 2)
            const [, info] = message.split(':', 2)
            setPaymentError(info)
          }
        }
        setPayment({
          totalPayment: null,
          partialPayment: null,
          tallerPayment: null,
        })
        setPaymentAttempt((paymentAttempt) => paymentAttempt + 1)
        return
      }

      // if (data.iframe) {
      //   // const width = parseInt(data.iframe.width)
      //   const height = parseInt(data.iframe.height)
      //   if (height === 0 || height + 'px' === formStyles[paymentType].height) {
      //     return
      //   }

      //   setFormStyles({
      //     ...formStyles,
      //     [paymentType]: {
      //       ...formStyles[paymentType],
      //       // aquest height haura d'anar fora
      //       height,
      //     },
      //   })
      // }

      if (data.RESULT) {
        // eslint-disable-next-line no-undef
        const code = atob(data.RESULT)
        setPaymentLoading(true)
        const { orderNumber } = await api.paymentResult(data, {
          fecha: cestaState.selectedDate.date,
          hora: cestaState.selectedDate.time,
        })
        setPaymentLoading(false)

        if (code === '00') {
          dispatch(CestaActions.addPaymentType(paymentType))
          dispatch(CestaActions.addCestaOrder(orderNumber))
          dispatch(CestaActions.setStep(Steps.TU_RESERVA))
          navigate(DataSteps[Steps.TU_RESERVA].route)
          return
        }
        setPayment({
          totalPayment: null,
          partialPayment: null,
          tallerPayment: null,
        })
        setPaymentAttempt((paymentAttempt) => paymentAttempt + 1)
        if (code === '103') {
          setPaymentError(t('cesta.pago.error.103'))
        } else if (code === '107') {
          setPaymentError(t('cesta.pago.error.107'))
        } else if (code === '666') {
          setPaymentError(t('cesta.pago.error.666'))
        } else if (code.startsWith('2')) {
          setPaymentError(t('cesta.pago.error.2xx'))
        } else if (code.startsWith('3')) {
          setPaymentError(t('cesta.pago.error.2xx'))
        } else if (code.startsWith('5')) {
          setPaymentError(t('cesta.pago.error.2xx'))
        } else {
          setPaymentError(t('cesta.pago.error.1xx'))
        }
      }
    }

    window.addEventListener('message', listener, false)
    return () => {
      window.removeEventListener('message', listener)
    }
  }

  useEffect(iFramesListener, [
    paymentType,
    formStyles,
    setFormStyles,
    setPaymentAttempt,
  ])

  // Check if we have all data to change Datos de Facturación layout
  const [forceEdit, setForceEdit] = useState(false)

  const hasDetails = hasFullUserDetails(userData, addressData)
  const showForm = !hasDetails || forceEdit

  useEffect(() => setPaymentType(paymentType), [paymentType])

  return (
    <React.Fragment>
      <form
        onSubmit={form.handleSubmit(onSubmitFacturacion)}
        className={styles.form}>
        <div className={styles.desktopTitles}>
          <TopContent tipo_compra={tipo_compra} paymentType={paymentType} />
        </div>
        {payment.partialPayment &&
          !payment.partialPayment.hide &&
          payment.totalPayment &&
          !payment.totalPayment.hide && (
            <h2>{t('datos_facturacion.que_prefieres')}</h2>
          )}

        {/* Label for accessibily, spans porque no se pueden poner block-elements en un label  */}
        <div className={styles.radios}>
          {payment.partialPayment && !payment.partialPayment.hide && (
            <label htmlFor="pagoparcial" className={styles.container}>
              <input
                id="pagoparcial"
                type="radio"
                name="pago"
                ref={form.register}
                value={PAYMENT_PARCIAL}
                defaultChecked={payment.partialPayment.defaultChecked}
              />
              <span className={styles.radio_description}></span>

              <span className={styles.radio_labels}>
                <span className={styles.label1}>
                  {t('datos_facturacion.pagar_solo_reserva')}
                </span>
                {payment.totalPayment && !payment.totalPayment.hide && (
                  <span className={styles.label2}>
                    {t('datos_facturacion.resto_en_taller')}
                  </span>
                )}
              </span>

              <span className={styles.radio_price}>
                {payment.partialPayment ? (
                  <Price value={payment.partialPayment.AMOUNT} />
                ) : (
                  <SpinnerDotted loading={true} />
                )}
              </span>
            </label>
          )}
          {payment.totalPayment && !payment.totalPayment.hide && (
            <label htmlFor="pagototal" className={styles.container}>
              <input
                id="pagototal"
                type="radio"
                name="pago"
                ref={form.register}
                value={PAYMENT_TOTAL}
                defaultChecked={payment.totalPayment.defaultChecked}
              />
              <span className={styles.radio_description}></span>

              <span className={styles.radio_labels}>
                <span className={styles.label1}>
                  {t('datos_facturacion.pagar_importe_total')}
                </span>
                {payment.partialPayment && !payment.partialPayment.hide && (
                  <span className={styles.label2}>
                    {t('datos_facturacion.prefieres_pagar_todo')}
                  </span>
                )}
              </span>

              <span className={styles.radio_price}>
                {payment.totalPayment ? (
                  <Price value={payment.totalPayment.AMOUNT} />
                ) : (
                  <SpinnerDotted loading={true} />
                )}
              </span>
            </label>
          )}
          {payment.tallerPayment && !payment.tallerPayment.hide && (
            <label htmlFor="pagotaller" className={styles.container}>
              <input
                id="pagotaller"
                type="radio"
                name="pago"
                ref={form.register}
                value={PAYMENT_TALLER}
                defaultChecked={false}
              />
              <span className={styles.radio_description}></span>

              <span className={styles.radio_labels}>
                <span className={styles.label1}>
                  {t('datos_facturacion.pagar_importe_taller')}
                </span>
                <span className={styles.label2}>
                  {t('datos_facturacion.prefieres_pagar_taller')}
                </span>
              </span>
            </label>
          )}
        </div>
        <h3 className={styles.datos_facturacion_title}>
          {t('datos_facturacion.title')}
        </h3>
        <div
          className={
            !datosFacturacion
              ? styles.facturacion_container
              : styles.facturacion_container_dis
          }>
          <DatosFacturacionForm
            form={form}
            onChangeCP={onChangeCP}
            requireMatricula={requireMatricula}
            userData={userData}
            addressData={addressData}
            showForm={showForm || requireMatricula}
          />
          <div
            className={`${styles.buttons} ${
              !forceEdit ? styles.no_margin_left : ''
            }`}>
            <Button buttonClass="rodi_button_with_icon" type="submit">
              {t('facturacion_form.submit')}
              <Next />
            </Button>
            {forceEdit || !hasDetails ? null : (
              <button
                className={styles.edit_data}
                onClick={() => setForceEdit(true)}>
                {t('datos_facturacion.editar')}
              </button>
            )}
          </div>
        </div>
      </form>
      {loading && (
        <SpinnerWithText loading={loading} text={t('cesta.pago.procesando')} />
      )}
      <h3 className={styles.datos_facturacion_title}>
        {t('datos_pago.title')}
      </h3>
      {paymentType !== 'taller' && !loading && (
        <div
          className={
            datosFacturacion ? styles.paymentTab : styles.paymentTab_dis
          }>
          {paymentError && <p className={styles.error}>{paymentError}</p>}
          <div>
            {payment.totalPayment &&
              datosFacturacion &&
              paymentType === PAYMENT_TOTAL && (
                <PaymentForm
                  paymentObject={payment.totalPayment}
                  datosFacturacion={datosFacturacion}
                  userData={userData}
                  style={{
                    ...formStyles[PAYMENT_TOTAL],
                    display: paymentType === PAYMENT_TOTAL ? 'block' : 'none',
                  }}
                  onFirstLoad={submitForm}
                />
              )}
            {payment.partialPayment &&
              datosFacturacion &&
              paymentType === PAYMENT_PARCIAL && (
                <PaymentForm
                  paymentObject={payment.partialPayment}
                  datosFacturacion={datosFacturacion}
                  userData={userData}
                  style={{
                    ...formStyles[PAYMENT_PARCIAL],
                    display: paymentType === PAYMENT_PARCIAL ? 'block' : 'none',
                  }}
                  onFirstLoad={submitForm}
                />
              )}
            {!paymentLoading &&
            (payment.partialPayment || payment.totalPayment) ? (
              <p className={styles.link}>
                {t('facturacion_form.condiciones')}
                <a
                  className={styles.link_bold}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={route('condiciones.index')}>
                  {t('facturacion_form.condiciones2')}
                </a>
              </p>
            ) : null}
          </div>
          {paymentLoading && (
            <SpinnerWithText
              loading={paymentLoading}
              text={t('datos_facturacion.pago_en_proceso')}
            />
          )}
        </div>
      )}
      {payment.tallerPayment &&
        datosFacturacion &&
        paymentType === PAYMENT_TALLER &&
        !loading && (
          <div className={styles.taller_payment_continue}>
            <Button
              buttonClass="rodi_button_with_icon"
              onClick={(e) => onSubmitPagoTaller(e)}>
              {t('datos_pago.confirmacion')}
              <Next />
            </Button>
          </div>
        )}
    </React.Fragment>
  )
}

const CestaPago: React.FC<CestaPageProps> = () => {
  const { cestaState } = useContext(AppContext)
  const tipo_compra = needsDatesExtends(cestaState)
  const [paymentType, setPaymentType] = useState(null)
  return (
    <LayoutSelector
      stepActive={cestaState.step}
      customSteps={DataSteps}
      hideSelectorSteps={false}
      title={t('steps.pago_reserva_title')}
      subTitle={t('steps.pago_reserva')}
      mobileStep={5}
      totalSteps={6}
      showCopyrightFooter>
      <div className={styles.mobileTitles}>
        <TopContent tipo_compra={tipo_compra} paymentType={paymentType} />
      </div>
      <div className={styles.pago_container_left}>
        <Contenido tipo_compra={tipo_compra} setPaymentType={setPaymentType} />
      </div>
      <FunnelSideBar currentLocation="cesta" cestaState={cestaState} />
    </LayoutSelector>
  )
}

export default CestaPago
