import { navigate } from 'gatsby'
import React, { useContext } from 'react'
import { t } from '../../../i18n'
import * as CestaActions from '../../../context/actions/cestaActions'
import AppContext from '../../../context/context'
import Size from '../../../utils/media-queries-setup'
import FunnelSideBar from '../../funnelSideBar/FunnelSideBar'
import LayoutSelector from '../../layouts/layoutSelector/LayoutSelector'
import CestaLastRow from '../cestaItem/cestaLastRow/CestaLastRow'
import { CestaPageProps } from '../registro/CestaRegistro'
import { DataSteps, Steps } from '../Steps'
import styles from './index.module.scss'
import { CestaOrigin } from '../../../context/reducers/cestaReducer'
import CocheOpciones from './CocheOpciones'
import MotoOpciones from './MotoOpciones'
import BateriaOpciones from './BateriaOpciones'
import AceiteOpciones from './AceiteOpciones'
import {
  isCestaAceite,
  isCestaBateria,
  isCestaCoche,
  isCestaMoto,
} from '../../../../shared/cesta/utils'
import { selectImporteTotal } from '../../../context/selectors/cestaSelectors'

const Opciones: React.FC<CestaPageProps> = () => {
  const { cestaState, dispatch } = useContext(AppContext)
  const onComprarClick = () => {
    const nextStep =
      cestaState.origin === CestaOrigin.External
        ? Steps.FECHA_HORA
        : Steps.TALLERES
    // move to next step
    dispatch(CestaActions.setStep(nextStep))
    return navigate(DataSteps[nextStep].route)
  }

  const onBackClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault()
    dispatch(CestaActions.setStep(Steps.TU_SOLICITUD))
    return navigate(DataSteps[Steps.TU_SOLICITUD].route)
  }

  return (
    <LayoutSelector
      stepActive={cestaState.step}
      customSteps={DataSteps}
      hideSelectorSteps={false}
      subTitle={t('opciones.title')}
      mobileStep={1}
      totalSteps={6}>
      <div className={styles.tu_solicitud_container}>
        <div className={styles.tu_solicitud}>
          <p className={styles.title}>
            {t('cesta.opciones.servicios_interesantes')}
          </p>
          <div className={styles.iva_incl_mobile}>
            {t('tu_solicitud.iva_incluido')}
          </div>
          {isCestaCoche(cestaState.products) ? (
            <CocheOpciones />
          ) : isCestaMoto(cestaState.products) ? (
            <MotoOpciones />
          ) : isCestaBateria(cestaState.products) ? (
            <BateriaOpciones />
          ) : isCestaAceite(cestaState.products) ? (
            <AceiteOpciones />
          ) : null}
        </div>
        <Size.Matcher
          desktop={
            <CestaLastRow
              onContinuarClick={onComprarClick}
              onBackClick={onBackClick}
            />
          }
          mobile={null}
        />
        <div className={styles.iva_incl}>{t('tu_solicitud.iva_incluido')}</div>
      </div>
      <Size.Matcher
        mobile={
          <CestaLastRow
            onContinuarClick={onComprarClick}
            comprarMobile={true}
            priceWithServices={selectImporteTotal(cestaState)}
          />
        }
        desktop={<FunnelSideBar cestaState={cestaState} />}
      />
    </LayoutSelector>
  )
}

export default Opciones
